import smallBannerAds from '../../renderers/smallBannerAds'

export default class GamesModal {
  constructor({ selector }) {
    this.elems = [...document.querySelectorAll(selector)]
    if (!this.elems.length) {
      return
    }
    this.container = this.elems[0]
    const { device, domain } = this.container.dataset
    this.device = device
    this.domain = domain || 'wwwaolcom'
    this.init()
  }

  init() {
    this.showAdAfterGameVideoPreRoll()
  }

  adConfigForGameModal(device, domain) {
    const smallBannerAdsConfig = {
      instance: '1',
      device: window.innerWidth < 700 ? 'smartphone' : device,
      type: 'small',
      domain
    }

    return { smallBannerAdsConfig }
  }

  emptyAdContainer(adContainer) {
    while (adContainer.hasChildNodes()) {
      adContainer.removeChild(adContainer.firstChild)
    }
  }

  renderBannerAds(adsConfig, clearAd = false) {
    const { instance, device, type, domain } = adsConfig
    const adId = `m-games-overlay-${type}-banner-ads`
    const bannerAdElement = document.getElementById(adId)

    let gameModalAdsHTML = null
    if (type === 'small') {
      gameModalAdsHTML = smallBannerAds(instance, device, domain)
    }

    this.emptyAdContainer(bannerAdElement)
    bannerAdElement.appendChild(gameModalAdsHTML)

    const AdIdElement = document.getElementById(`mid_center_${instance}`)

    if (window?.GAM?.destroyPlacement && AdIdElement) {
      window.GAM?.destroyPlacement([`mid_center_${instance}`])
    }

    if (!clearAd) {
      if (window?.GAM?.createPlacement) {
        const ad = window.GAM.createPlacement(AdIdElement)
        ad.create()
      }
    }
  }

  showAdAfterGameVideoPreRoll() {
    let timeOut = null
    const self = this
    timeOut = setInterval(() => {
      const checkGameFrame = !!document.querySelector('.m-game-frame')
      if (
        checkGameFrame &&
        window?.GameFrame?.isGameVideoAdCompleted &&
        window?.GameFrame?.isGameVideoAdCompleted()
      ) {
        const { smallBannerAdsConfig } = self.adConfigForGameModal(self.device, self.domain)
        self.renderBannerAds(smallBannerAdsConfig)
        clearInterval(timeOut)
        timeOut = null
      }
    }, 30)
  }
}
