// This function gets the placeholder [width,height] for a GAM ad.
// Current logic is to return the smallest available size available
const getPlaceholderSize = (adConfig = {}) => {
  const { size = [] } = adConfig
  // These heights are used for e2e ads
  const sizesToExclude = [1, 2]
  const smallestAdSize = size.reduce(
    (placeholderSize, current) => {
      const [, pHeight] = placeholderSize
      const [, height] = current
      if (!sizesToExclude.includes(height) && (!pHeight || height < pHeight)) {
        placeholderSize = current
      }
      return placeholderSize
    },
    [0, 0]
  )
  return smallestAdSize
}

const getBenjiPath = (adConfig = {}, device) => {
  const { path = '' } = adConfig
  if (typeof path === 'string') {
    return path
  }
  return path[device] || path.default
}
module.exports = {
  getPlaceholderSize,
  getBenjiPath
}
