const el = require('../../../lib/utils/jsx_component');
const adsUtils = require('../../../lib/utils/ads');
const smallBannerAds = (instance, device, domain) => {
  let path = '/22888152279/us/aol/ros/dt/us_aol_ros_dt_mid_center';
  let smartPhonePath = '/22888152279/us/aol/ros/mw/us_aol_ros_mw_mid_center';
  if (domain === 'wwwaolcouk') {
    path = '/22888152279/gb/aol/ros/dt/gb_aol_ros_dt_mid_center';
    smartPhonePath = '/22888152279/gb/aol/ros/mw/gb_aol_ros_mw_mid_center';
  } else if (domain === 'wwwaolca') {
    path = '/22888152279/ca/aol/ros/dt/ca_aol_ros_dt_mid_center';
    smartPhonePath = '/22888152279/ca/aol/ros/mw/ca_aol_ros_mw_mid_center';
  }
  const adsConfig = {
    id: `mid_center_${instance}`,
    size: [[728, 90]],
    region: 'index',
    kvs: {
      loc: `mid_center_${instance}`
    },
    path
  };
  if (device === 'smartphone') {
    adsConfig.path = smartPhonePath;
    adsConfig.size = [[320, 50]];
  }
  const [width, height] = adsUtils.getPlaceholderSize(adsConfig);
  const minWidth = `${width}px`;
  const minHeight = `${height}px`;
  return el("div", {
    className: "m-games-modal-small-banner-ad",
    "data-instance": instance
  }, el("div", {
    "data-config": JSON.stringify(adsConfig),
    style: JSON.stringify({
      'min-height': minWidth,
      'min-width': minHeight
    }),
    id: adsConfig.id,
    className: "m-gam m-gam--loaded"
  }));
};
module.exports = smallBannerAds;